<template>
  <div>
    <b-row v-if="driver.length > 0">
      <b-col cols="12">
        <b-card class="my-card m-0">
          <b-table
            striped
            small
            id="myTables"
            bordered
            selectable
            selected-variant="primary"
            select-mode="single"
            responsive="sm"
            :items="driver"
            :fields="fields"
            @row-clicked="clicked"
            head-row-variant="primary"
          >
            <template #cell(checkbox)="row">
              <b-form-checkbox
                v-model="row.detailsShowing"
                plain
                class="vs-checkbox-con"
                @change="row.toggleDetails"
              >
              </b-form-checkbox>
            </template>
            <template #cell(first_name)="data">
              <span>
                {{
                  data.item.patronymic
                    ? data.item.last_name +
                      " " +
                      data.item.first_name +
                      " " +
                      data.item.patronymic
                    : data.item.last_name + " " + data.item.first_name
                }}
              </span>
            </template>
            <template #cell(gender)="data">
              <span v-if="data.item.gender == 1"> Мужской </span>
              <span v-else> Женский </span>
            </template>
            <template #cell(rating)="data">
              <span
                @dblclick="getratingInformation(data)"
                class="reference-field"
              >
                {{ data.item.rating }}
              </span>
            </template>
            <template #cell(driver_cars)="data">
              <span
                @dblclick="getCar(data)"
                v-for="car in data.item.driver_cars"
                :key="car.id"
                class="reference-field"
                select-variant="secondary"
              >
                {{
                  data.item.driver_cars.length > 0
                    ? car.car_brand +
                      " " +
                      car.model +
                      "(" +
                      car.car_number +
                      ")" +
                      ", "
                    : ""
                }}
              </span>
            </template>
            <template #cell(balance)="data">
              <span
                class="reference-field"
                v-if="data.item.balance != null"
                @dblclick="getBalans(data)"
              >
                {{ data.item.balance.balance }}
              </span>
            </template>
            <template #cell(report)="data">
              <span @dblclick="getMemos(data)" class="reference-field">
                (***)
              </span>
            </template>
            <template #cell(last_order_complated_date)="data">
              <span @dblclick="getPathData(data)" class="reference-field">
                {{ data.item.last_order_complated_date }}
              </span>
            </template>
            <template #cell(photo_control)="data">
              <span @dblclick="getPhotoControl(data)" class="reference-field">
                (***)
              </span>
            </template>
          </b-table>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { resizeable } from "@/utils/TableFunctions/resizeable.js";
import { cellSelect } from "@/utils/TableFunctions/cellSelect";
export default {
  props: ["driver"],
  data() {
    return {
      fields: [
        { key: "checkbox", label: "", thStyle: { width: "30px" } },
        { key: "id", label: "Id", sortable: true, thStyle: { width: "40px" } },
        {
          key: "login",
          label: "Логин",
          sortable: true,
          thStyle: { width: "70px" },
        },
        {
          key: "balance",
          label: "Баланс",
          sortable: true,
          thStyle: { width: "70px" },
        },
        {
          key: "rating",
          label: "Рейтинг",
          sortable: true,
          thStyle: { width: "70px" },
        },
        {
          key: "created_by.login",
          label: "Создал",
          sortable: true,
          thStyle: { width: "127px" },
        },
        {
          key: "place_district",
          label: "Текущий район",
          sortable: true,
          thStyle: { width: "150px" },
        },
        {
          key: "division",
          label: "Подразделение",
          sortable: true,
          thStyle: { width: "150px" },
        },
        {
          key: "first_name",
          label: "Ф.И.О.",
          sortable: true,
          thStyle: { width: "250px" },
        },
        {
          key: "status_auto",
          label: "Статус",
          sortable: true,
          thStyle: { width: "70px" },
        },
        {
          key: "is_block",
          label: "Состояния",
          sortable: true,
          thStyle: { width: "70px" },
        },
        {
          key: "date_of_birth",
          label: "Дата рождения",
          sortable: true,
          thStyle: { width: "130px" },
        },
        {
          key: "phone",
          label: "Телефон",
          sortable: true,
          thStyle: { width: "116px" },
        },
        {
          key: "contact_number",
          label: "Доп. номер тел.",
          sortable: true,
          thStyle: { width: "150px" },
        },
        {
          key: "driver_cars",
          label: "Машины",
          sortable: true,
          thStyle: { width: "150px" },
        },
        {
          key: "serials_number",
          label: "Номер вод.удостоверения",
          sortable: true,
          thStyle: { width: "150px" },
        },
        {
          key: "expirated_driver_license",
          label: "Срок истечения вод. удостоверение",
          sortable: true,
          thStyle: { width: "150px" },
        },
        {
          key: "gender",
          label: "Пол",
          sortable: true,
          thStyle: { width: "150px" },
        },
        {
          key: "serial_number_passport",
          label: "Номер паспорта",
          sortable: true,
          thStyle: { width: "150px" },
        },
        {
          key: "expirated_passport",
          label: "Срок истечения паспорта",
          sortable: true,
          thStyle: { width: "150px" },
        },
        {
          key: "passport_office",
          label: "Орган, выдающий документ",
          sortable: true,
          thStyle: { width: "150px" },
        },
        {
          key: "address",
          label: "Адрес",
          sortable: true,
          thStyle: { width: "150px" },
        },
        {
          key: "report",
          label: "СЗ",
          sortable: true,
          thStyle: { width: "150px" },
        },
        {
          key: "district",
          label: "Район",
          sortable: true,
          thStyle: { width: "150px" },
        },
        {
          key: "year_of_issue",
          label: "Год мошини",
          sortable: true,
          thStyle: { width: "150px" },
          formatter: "yearOfIssueFormatter",
        },
        {
          key: "created_at",
          label: "Дата регистрации",
          sortable: true,
          thStyle: { width: "150px" },
        },
        {
          key: "last_order_complated_date",
          label: "Дата посл. заказа по подключению",
          sortable: true,
          thStyle: { width: "150px" },
        },
        {
          key: "photo_control",
          label: "Фотоконтроль",
          sortable: true,
          thStyle: { width: "150px" },
        },
      ],
    };
  },
  mounted() {
    resizeable();
  },
  methods: {
    getPathData(data) {
      this.$store.commit("draggableTab/ADD_TAB", [
        "История " + data.item.id,
        `/taxi/driver/drivers-history-orders`,
        data.item.id,
      ]);
      this.$router.push({
        name: "drivers/drivers-history-orders",
        query: { driver_id: data.item.id },
      });
    },
    getMemos(data) {
      this.$router.push({
        name: "driversMemos",
        query: { filter: data.item.balance.performer_id },
      });
      this.$store.commit("draggableTab/ADD_TAB", [
        "Служебные записки " + data.item.balance.performer_id,
        `./taxi/driversMemos/index`,
        data.item.balance.performer_id,
      ]);
    },
    getPhotoControl(data) {
      this.$router.push({
        name: "photo-control/performer-assign/searchInfo",
        query: { filter: data.item.id },
      });
      this.$store.commit("draggableTab/ADD_TAB", [
        "Фотоконтроль " + data.item.id,
        `/taxi/photo-controlInfo`,
        data.item.id,
      ]);
    },
    getratingInformation(data) {
      this.$store.commit("draggableTab/ADD_TAB", [
        "Рейтинг " + data.item.id,
        `/taxi/rating/index`,
        data.item.id,
      ]);
      this.$router.push({ name: "rating", query: { filter: data.item.id } });
    },
    getBalans(data) {
      this.$router.push({
        name: "balance",
        query: { driver_id: data.item.balance.performer_id },
      });
      this.$store.commit("draggableTab/ADD_TAB", [
        "Баланс " + data.item.balance.performer_id,
        `/driver/balance`,
        data.item.balance,
      ]);
    },
    getCar(data) {
      this.$router.push({ name: "carInfo", query: { filter: data.item.id } });
      this.$store.commit("draggableTab/ADD_TAB", [
        "Автомобиль " + data.item.id,
        `/driver/carInfo`,
        data.item.id,
      ]);
    },
    clicked(item, index, event) {
      this.$store.state.filterPlus.tableIndex = index;
      const clickedElement = event.target;
      const clickedCell = clickedElement.closest("td");

      if (!clickedCell) {
        return;
      }

      const columnIndex = Array.from(clickedCell.parentNode.children).indexOf(
        clickedCell
      );

      if (columnIndex < 0 || columnIndex >= this.fields.length) {
        return;
      }
      const clickedField = this.fields[columnIndex];
      this.$store.state.filterPlus.keyFilter = clickedField.key;
      this.$store.state.filterPlus.filterPlus = event.target.textContent.trim();
      cellSelect();
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/table.scss";
.my-card .card-body {
  padding: 0px;
}
.my-card {
  overflow: auto;
  height: calc(100vh - 92px);
}
@media (max-height: 700px) {
  .my-card {
    overflow: auto;
    height: 530px;
  }
}
</style>
